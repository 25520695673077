import { template as template_5c248db0d72e45fa8faff282d0067a31 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_5c248db0d72e45fa8faff282d0067a31(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
