import { template as template_c8ba1c8f1baf4438a1c00c4a4b5c7365 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c8ba1c8f1baf4438a1c00c4a4b5c7365(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
