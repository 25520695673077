import { template as template_358b6a53d84e43ceb709944a42387619 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_358b6a53d84e43ceb709944a42387619(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
