import { template as template_b50de89ee83447d6aa73498075596672 } from "@ember/template-compiler";
const FKLabel = template_b50de89ee83447d6aa73498075596672(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
